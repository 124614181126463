// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(to bottom, #002c47, #00b4d8); /* Gradient background */
    padding: 20px;
    color: #ffffff;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 20px;
  }
  
  .footer-section h4 {
    margin-bottom: 10px;
  }
  
  .footer-section p {
    margin: 5px 0;
  }
  
  .footer-section a {
    color: #ffffff;
    text-decoration: none;
  }
  
  .footer-section a:hover {
    text-decoration: underline;
  }
  
  .footer-copyright {
    text-align: center;
    font-size: 12px; /* Smaller font size for the copyright */
    width: 100%;
    margin-top: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/Footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,mBAAmB;IACnB,wDAAwD,EAAE,wBAAwB;IAClF,aAAa;IACb,cAAc;EAChB;;EAEA;IACE,aAAa;IACb,8BAA8B;IAC9B,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,cAAc;EAChB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,aAAa;EACf;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,kBAAkB;IAClB,eAAe,EAAE,wCAAwC;IACzD,WAAW;IACX,gBAAgB;EAClB","sourcesContent":[".footer {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    align-items: center;\n    background: linear-gradient(to bottom, #002c47, #00b4d8); /* Gradient background */\n    padding: 20px;\n    color: #ffffff;\n  }\n  \n  .footer-content {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n  }\n  \n  .footer-section {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    margin: 0 20px;\n  }\n  \n  .footer-section h4 {\n    margin-bottom: 10px;\n  }\n  \n  .footer-section p {\n    margin: 5px 0;\n  }\n  \n  .footer-section a {\n    color: #ffffff;\n    text-decoration: none;\n  }\n  \n  .footer-section a:hover {\n    text-decoration: underline;\n  }\n  \n  .footer-copyright {\n    text-align: center;\n    font-size: 12px; /* Smaller font size for the copyright */\n    width: 100%;\n    margin-top: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
