import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../styles/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h4>Conditions d'utilisation</h4>
          <p><Link to="/conditions">Conditions Générales de Vente</Link></p>
        </div>
        <div className="footer-section">
          <h4>Redirection de Pages</h4>
          <p><Link to="/pro">Espace Pro</Link></p>
          <p><Link to="/particulier">Particulier</Link></p>
          <p><Link to="/prestataire">Hub Prestataire</Link></p>
        </div>
        <div className="footer-section">
          <h4>Contact</h4>
          <p><a href="mailto:support@ecotoit.io">support@ecotoit.io</a></p>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 Ecotoit. Tous droits réservés.</p>
      </div>
    </footer>
  );
};

export default Footer;
