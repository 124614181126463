import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Web3 from 'web3';
import CryptoJS from 'crypto-js';
import contractABI from '../abi/EcoToitNFT.json'; // Importez l'ABI de votre contrat
import tokenABI from '../abi/EcoToitToken.json'; // Importez l'ABI de votre token

const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

const GlobalStateContext = createContext();
const GlobalStateUpdateContext = createContext();

export const useGlobalState = () => useContext(GlobalStateContext);
export const useGlobalStateUpdate = () => useContext(GlobalStateUpdateContext);

export const GlobalStateProvider = ({ children }) => {
  const [nftName, setNftName] = useState('');
  const [nftImage, setNftImage] = useState(null);
  const [nftAddress, setNftAddress] = useState('');
  const [nfts, setNfts] = useState([]);
  const [selectedNft, setSelectedNft] = useState(null);
  const [showPrestation, setShowPrestation] = useState(false);
  const [selectedPrestation, setSelectedPrestation] = useState('');
  const [userAddress, setUserAddress] = useState('');
  const [error, setError] = useState('');
  const [servicePrices, setServicePrices] = useState({});
  const [services, setServices] = useState([]);
  const [reports, setReports] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const hasCheckedConnection = useRef(false);

  const titleServiceDescription = {
    0: "Inspection et Evaluation du Toit",
    1: "Nettoyage de Toit",
    2: "Réparation de Toit",
    3: "Étanchéification"
  };

  const serviceDescriptions = {
    0: "Examen complet du toit pour détecter les dommages, l'usure, et les zones nécessitant des réparations. Cela inclut l'inspection des bardeaux, des tuiles, des gouttières et des évents.",
    1: "Nettoyage des débris, des mousses, des algues et des lichens qui peuvent endommager le toit.",
    2: "Réparation des fuites, remplacement des tuiles ou bardeaux endommagés, et colmatage des fissures.",
    3: "Application de produits d'étanchéité et de traitements préventifs pour protéger le toit contre les intempéries et les infiltrations d'eau.",
  };

  let web3;
  if (typeof window !== "undefined" && typeof window.ethereum !== "undefined") {
    web3 = new Web3(window.ethereum);
  } else {
    const providerURL = 'https://etherscan.cardona.zkevm-rpc.com/';
    web3 = new Web3(providerURL);
  }

  const contractAddress = '0x803cBCecf622A53e537c838166a32932B3537bBB';
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tokenAddress = '0x59BAf1a5a358Cf9F17101Ca29c6CA8749767b9BD';
  const tokenContract = new web3.eth.Contract(tokenABI.abi, tokenAddress);

  const checkConnection = async (fromHeader) => {
    if (fromHeader) {
      hasCheckedConnection.current = false;
    }
    if (window.ethereum && !hasCheckedConnection.current) {
      hasCheckedConnection.current = true;
      try {
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        if (accounts.length > 0) {
          setUserAddress(accounts[0]);
          const cachedNfts = localStorage.getItem('nfts');
          const cacheTime = localStorage.getItem('cacheTime');
          const isCacheValid = cacheTime && (Date.now() - cacheTime < CACHE_DURATION);

          if (cachedNfts && isCacheValid) {
            const nftsWithUserAddress = JSON.parse((cachedNfts)).map(nft => ({
              ...nft,
              userAddress: accounts[0]
            }));
            setNfts(nftsWithUserAddress);
            setIsAuthenticated(localStorage.getItem('isAuthenticated') === 'true');
          } else if (!isAuthenticating && !isAuthenticated) {
            setIsAuthenticating(true);
            await authenticateUser(accounts[0]);
            setIsAuthenticating(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    checkConnection();
  }, []);

  useEffect(() => {
    const fetchServicePrices = async () => {
      try {
        const prices = {};
        for (let i = 0; i <= 3; i++) {
          const price = await contract.methods.getServicePrice(i).call();
          prices[i] = web3.utils.fromWei(price, 'ether');
        }
        setServicePrices(prices);
      } catch (error) {
        console.log(error);
      }
    };
    fetchServicePrices();
  }, []);

  async function fetchEncryptedMetadata(data) {
    const ipfsHash = data.split('ipfs://')[1];
    const url = `https://ipfs.ecotoit.io:8443/ipfs/${ipfsHash}`;

    try {
      const response = await axios.get(url);
      return response.data;
    } catch (error) {
      console.error('Error fetching encrypted metadata from IPFS:', error);
      throw new Error('Failed to fetch encrypted metadata from IPFS');
    }
  }

  const authenticateUser = async (address) => {
    try {
      const response = await fetch('https://api.ecotoit.io/initiate-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userAddress: address })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const message = data.message;
      const signature = await web3.eth.personal.sign(message, address, '');

      const verifyResponse = await fetch('https://api.ecotoit.io/verify-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userAddress: address, signature })
      });

      if (!verifyResponse.ok) {
        throw new Error(`HTTP error! status: ${verifyResponse.status}`);
      }

      const nfts = await verifyResponse.json();
      const decryptedNfts = await Promise.all(nfts.map(async (nft) => {
        try {
          const encryptedMetadata = await fetchEncryptedMetadata(nft.encryptedMetadataURI);
          if (!encryptedMetadata) {
            throw new Error('Failed to fetch encrypted metadata');
          }

          const decryptedMetadataBytes = CryptoJS.AES.decrypt(encryptedMetadata, nft.signature);
          const decryptedMetadataStr = decryptedMetadataBytes.toString(CryptoJS.enc.Utf8);

          if (!decryptedMetadataStr) {
            throw new Error('Failed to decrypt metadata');
          }

          let decryptedMetadata;
          try {
            decryptedMetadata = JSON.parse(decryptedMetadataStr);
          } catch (jsonError) {
            throw new Error('Failed to parse decrypted metadata JSON');
          }

          const encryptedImage = await fetchEncryptedMetadata(decryptedMetadata.image);

          const decryptedImageBytes = CryptoJS.AES.decrypt(encryptedImage, nft.signature);
          const decryptedImage = decryptedImageBytes.toString(CryptoJS.enc.Utf8);
          return {
            tokenId: `${nft.tokenId}`,
            ...decryptedMetadata,
            userAddress: `${nft.userAddress}`,
            signature: `${nft.signature}`,
            analysis: `${nft.analysis}`,
            image: `${decryptedImage}`
          };
        } catch (error) {
          console.error('Error decrypting NFT data:', error);
          throw error;
        }
      }));

      setNfts(decryptedNfts);
      setIsAuthenticated(true);

      // Compresser les données avant de les stocker
      console.log(decryptedNfts);
      localStorage.setItem('nfts', JSON.stringify(decryptedNfts));
      localStorage.setItem('cacheTime', Date.now().toString());
      localStorage.setItem('isAuthenticated', 'true');

    } catch (error) {
      console.log(error);
    }
  };

  const switchChain = async () => {
    try {
      const web3 = new Web3(window.ethereum);
      await window.ethereum.request({ method: 'eth_requestAccounts' });
      const accounts = await web3.eth.getAccounts();
      const chainId = await web3.eth.getChainId();
      if (chainId !== 2442) {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: Web3.utils.toHex(2442) }],
        });
      }
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Web3.utils.toHex(2442),
                chainName: 'Cardona zkEVM',
                nativeCurrency: {
                  name: 'Cardona zkEVM',
                  symbol: 'CZD',
                  decimals: 18,
                },
                rpcUrls: ['https://etherscan.cardona.zkevm-rpc.com/'],
                blockExplorerUrls: ['https://etherscan.cardona.zkevm-rpc.com/'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add the network');
        }
      }
    }
  };

  const downloadTechnicalFile = async (nft) => {
    try {
      const encryptedinitialTechnicalFile = await fetchEncryptedMetadata(nft.technicalFile);

      const decryptedTechnicalFileBytes = CryptoJS.AES.decrypt(encryptedinitialTechnicalFile, nft.signature);
      const decryptedTechnicalFile = CryptoJS.enc.Base64.parse(decryptedTechnicalFileBytes.toString(CryptoJS.enc.Utf8));

      const byteArray = new Uint8Array(decryptedTechnicalFile.words.map(word => [
        (word >> 24) & 0xFF,
        (word >> 16) & 0xFF,
        (word >> 8) & 0xFF,
        word & 0xFF,
      ]).flat());

      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      const a = document.createElement('a');
      a.href = url;
      a.download = `${nft.name}_Technical_File.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading technical file:', error);
    }
  };

  const disconnectMetaMask = () => {
    setUserAddress('');
    localStorage.removeItem('account');
    localStorage.removeItem('nfts');
    localStorage.removeItem('cacheTime');
    localStorage.removeItem('isAuthenticated');
    setNfts([]);
    setIsAuthenticated(false);
  };

  return (
    <GlobalStateContext.Provider value={{
      nftName, nftImage, nftAddress, nfts, selectedNft, showPrestation,
      selectedPrestation, userAddress, error, servicePrices, services,
      reports, isAuthenticated, isAuthenticating, web3, contract, tokenContract,
      contractAddress, titleServiceDescription, serviceDescriptions, whitelist, downloadTechnicalFile,
    }}>
      <GlobalStateUpdateContext.Provider value={{
        setNftName, setNftImage, setNftAddress, setNfts, setSelectedNft, setShowPrestation,
        setSelectedPrestation, setUserAddress, setError, setServicePrices, setServices,
        setReports, setIsAuthenticated, setIsAuthenticating, disconnectMetaMask, authenticateUser,
        checkConnection, switchChain
      }}>
        {children}
      </GlobalStateUpdateContext.Provider>
    </GlobalStateContext.Provider>
  );
};
