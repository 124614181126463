import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React from 'react';
import { MoonPayProvider } from '@moonpay/moonpay-react';
import Header from './components/Header';
import Home from './pages/Home';
import PagePro from './pages/PagePro';
import PagePro2 from './pages/PagePro2';
import PageParticulier from './pages/PageParticulier';
import Footer from './components/Footer'; // Import Footer component
import CGVPage from './pages/CGV';

function App() {
  return (
    <MoonPayProvider 
      apiKey="pk_test_eazL9LDQ6TpY7cVkuriwowfJpLwzfN8o" 
      debug
    >
      <Router>
        <div>
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/prestataire" element={<PagePro />} />
            <Route path="/pro" element={<PagePro2 />} />
            <Route path="/particulier" element={<PageParticulier />} />
            <Route path="/conditions" element={<CGVPage />} />
          </Routes>
          <Footer/>
        </div>
      </Router>
    </MoonPayProvider>
  );
}

export default App;
