import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../styles/Carousel.css';
import logoloupe from '../assets/loupe.webp';
import logoshield from '../assets/shield.webp';
import logosceau from '../assets/sceau.webp';
import logoclef from '../assets/clef.webp';
import logoboard from '../assets/board.webp';
import logomontre from '../assets/montre.webp';

const steps = [
    {
        image: logoshield,
        title: 'Sécurité',
        description: 'La blockchain offre une sécurité supérieure grâce à sa structure décentralisée et cryptée, protégeant les données sensibles des entreprises contre les modifications non autorisées et les cyberattaques.',
      },
      {
        image: logoloupe,
        title: 'Transparence',
        description: "L'utilisation de la blockchain dans Ecotoit assure une transparence totale, permettant à toutes les parties prenantes d'accéder à l'historique complet des interventions réalisées sur une toiture.",
      },
      {
        image: logosceau,
        title: 'Immutabilité',
        description: "La blockchain garantit l'intégrité des données en empêchant toute modification ou suppression après enregistrement, ce qui est crucial pour la gestion des risques et la conformité réglementaire dans le secteur du bâtiment.",
      },
      {
        image: logoclef,
        title: 'Authentification Sécurisée',
        description:"Ecotoit utilise la blockchain pour assurer une authentification sécurisée grâce à des clés cryptographiques uniques, renforçant la sécurité des transactions et des interactions sur la plateforme.",
      },
      {
        image: logoboard,
        title: 'Gestion Simplifiée',
        description:"Ecotoit propose une interface utilisateur intuitive permettant de planifier et consulter facilement l'historique des interventions et des diagnostics de toiture, rendant la gestion quotidienne plus fluide.",
      },
      {
        image: logomontre,
        title: 'Suivi en Temps Réel',
        description:"Ecotoit offre un suivi en temps réel des interventions et des conditions des toitures, avec des mises à jour instantanées et des notifications personnalisables pour une transparence totale.",
      },
];

const CarouselComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, // Number of slides to show at once
    slidesToScroll: 1, // Number of slides to scroll at a time
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        {steps.map((step, index) => (
          <div key={index} className="carousel-item">
            <div className="carousel-item-content">
              <img src={step.image} alt={`Step ${index + 1}`} />
              <h3>{step.title}</h3>
              <p>{step.description}</p>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
