import React, { useCallback, useRef, useState, useEffect } from 'react';
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers';
import { SwapWidget, lightTheme } from '@uniswap/widgets';
import '@uniswap/widgets/fonts.css';
import { useGlobalState } from '../provider/GlobalStateProvider';
import '../styles/styles.css'

const TOKEN_LIST = 'https://ipfs.io/ipns/tokens.uniswap.org';
const UNI = '0x1f9840a85d5aF5bf1D1762F925BDADdC4201F984';
const JSON_RPC_URL = 'https://ethereum-rpc.publicnode.com';

export default function UniswapSwapCustom() {
    const { userAddress, web3 } = useGlobalState();
    const connectors = useRef(null);
    const [locale, setLocale] = useState('fr-FR');
    const [provider, setProvider] = useState(null);

    const focusConnectors = useCallback(() => connectors.current?.focus(), []);
    const onSelectLocale = useCallback((e) => setLocale(e.target.value), []);

    useEffect(() => {
        if (web3 && userAddress) {
            const ethersProvider = new Web3Provider(web3.currentProvider);
            setProvider(ethersProvider);
        } else {
            const ethersProvider = new JsonRpcProvider(JSON_RPC_URL);
            setProvider(ethersProvider);
        }
    }, [web3, userAddress]);

    return (
        <div>
            <div>
                <div ref={connectors} tabIndex={-1} style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    {/* Connect Wallet UI Component if needed */}
                </div>

                <div>
                    <h1>Achat avec Uniswap</h1>
                    {provider ? (
                        <SwapWidget
                            jsonRpcEndpoint={JSON_RPC_URL}
                            tokenList={TOKEN_LIST}
                            provider={provider}
                            locale={locale}
                            onConnectWallet={focusConnectors}
                            defaultInputTokenAddress="NATIVE"
                            defaultInputAmount={1}
                            defaultOutputTokenAddress={UNI}
                        />
                    ) : (
                        <p>Loading provider...</p>
                    )}
                </div>
            </div>
        </div>
    );
}
