import React from 'react';
import roofingImage from '../assets/solarpanel.webp'; // Utilisez le chemin correct pour votre image
import { useHover } from './HoverContext';
import '../styles/ProjectDescription.css';

const ProjectDescription = ({ imageRef }) => {
  const { hover, setHover } = useHover();

  return (
    <div
      className="project-description-container"
      
    >
      <div className="text-container fade-in-left">
        <h1>Découvrez Ecotoit</h1>
        <p>
          <strong className="highlight">Ecotoit</strong> révolutionne l'entretien des toitures grâce à des solutions innovantes basées sur la <strong className="highlight">blockchain</strong> et l'<strong className="highlight">intelligence artificielle</strong>. Notre approche intégrée améliore la gestion, la maintenance et le suivi des toitures, offrant une transparence et une efficacité <strong className="highlight">inégalées</strong>.
        </p>
        <p>
          Grâce à la <strong className="highlight">technologie blockchain</strong>, nous garantissons une sécurité optimale et une transparence totale pour les propriétaires, les prestataires de services et les compagnies d'assurance. L'<strong className="highlight">IA</strong> permet des diagnostics précis et une documentation détaillée, rendant la maintenance des toitures plus prévisible et efficace.
        </p>
        <p>
          En combinant des <strong className="highlight">technologies modernes</strong> avec l'expertise humaine, <strong className="highlight">Ecotoit</strong> simplifie la planification de la maintenance et la gestion des risques dans le secteur du bâtiment. Rejoignez-nous et découvrez une nouvelle manière de gérer vos toitures avec <strong className="highlight">Ecotoit</strong>.
        </p>
      </div>
      <div className="image-container fade-in-right" onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
        <img ref={imageRef} src={roofingImage} alt="Innovative Roofing Maintenance" className={`styled-image ${hover ? 'hover' : ''}`} />
      </div>
    </div>
  );
};

export default ProjectDescription;
