import React, { useEffect, useState, useRef } from 'react';
import { Line } from 'react-chartjs-2';
import bigInt from 'big-integer';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler } from 'chart.js';
import axios from 'axios';

// Register necessary components
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, Filler);

const PriceChart = () => {
  const [chartData, setChartData] = useState(null);
  const [timeframe, setTimeframe] = useState('1d'); // default timeframe
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const chartRef = useRef(null);

  const convertSqrtPriceX96ToPrice = (sqrtPriceX96) => {
    const price = (bigInt(sqrtPriceX96) ** bigInt(2)) / (bigInt(2) ** bigInt(192));
    return Number((1 / price) * 10 ** 12);
  };

  useEffect(() => {
    const fetchPriceData = async () => {
      try {
        const response = await axios.get(`https://api.ecotoit.io/price-data?timeframe=${timeframe}`);
        const prices = response.data.map(point => convertSqrtPriceX96ToPrice(point.price).toFixed(2));
        const timestamps = response.data.map(point => new Date(point.timestamp).toLocaleString());

        setChartData({
          labels: timestamps,
          datasets: [
            {
              label: 'Price (USD)',
              data: prices,
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const { ctx, chartArea } = chart;

                if (!chartArea) {
                  // This case happens on initial chart load
                  return null;
                }

                const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
                gradient.addColorStop(0, 'rgba(0, 136, 204, 0.2)');
                gradient.addColorStop(1, 'rgba(0, 136, 204, 0.7)');

                return gradient;
              },
              borderColor: 'rgba(0, 136, 204, 1)',
              pointRadius: 0,
              pointHoverRadius: 6, // Point size on hover
              pointBackgroundColor: 'rgba(0, 136, 204, 1)',
              tension: 0.4, // Line smoothing
            },
          ],
        });
      } catch (error) {
        console.error('Error fetching price data:', error);
      }
    };

    fetchPriceData();
  }, [timeframe]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index',
        intersect: false,
        callbacks: {
          label: (tooltipItem) => `Price (USD): ${tooltipItem.raw}`,
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 3, // Limit the number of displayed dates
        },
        grid: {
          display: false, // Remove the grid
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: 'USD',
        },
        grid: {
          display: false, // Remove the grid
        },
      },
    },
    elements: {
      line: {
        borderWidth: 2,
      },
      point: {
        hoverBackgroundColor: 'rgba(0, 136, 204, 1)',
      },
    },
    hover: {
      mode: 'nearest',
      intersect: false,
      onHover: (event, chartElement) => {
        if (chartElement.length) {
          const ctx = chartRef.current.ctx;
          const chart = chartRef.current.chartInstance;
          const { x, y } = chartElement[0].element.tooltipPosition();

          // Draw dashed lines
          ctx.save();
          ctx.beginPath();
          ctx.setLineDash([5, 5]);
          ctx.moveTo(x, chart.scales.y.top);
          ctx.lineTo(x, chart.scales.y.bottom);
          ctx.moveTo(chart.scales.x.left, y);
          ctx.lineTo(chart.scales.x.right, y);
          ctx.strokeStyle = 'rgba(0, 0, 0, 0.5)';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleTimeframeChange = (newTimeframe) => {
    setTimeframe(newTimeframe);
    setDropdownOpen(false);
  };

  return (
    <div className="chart-container dual-item" style={styles.chartContainer}>
      <h2>Prix EcoToit</h2>
      <div className="dropdown" style={styles.dropdown}>
        <button onClick={handleDropdownToggle} style={styles.dropdownButton}>
          {timeframe} ⏷
        </button>
        {dropdownOpen && (
          <div className="dropdown-menu" style={styles.dropdownMenu}>
            <button onClick={() => handleTimeframeChange('12h')} style={styles.dropdownItem}>12h</button>
            <button onClick={() => handleTimeframeChange('1d')} style={styles.dropdownItem}>1j</button>
            <button onClick={() => handleTimeframeChange('1m')} style={styles.dropdownItem}>1m</button>
            <button onClick={() => handleTimeframeChange('3m')} style={styles.dropdownItem}>3m</button>
            <button onClick={() => handleTimeframeChange('6m')} style={styles.dropdownItem}>6m</button>
            <button onClick={() => handleTimeframeChange('1a')} style={styles.dropdownItem}>1a</button>
          </div>
        )}
      </div>
      {chartData ? (
        <div style={{ height: 'calc(100% - 40px)', width:'100%' }}> {/* Adjust height to fit within container */}
          <Line ref={chartRef} data={chartData} options={options} />
        </div>
      ) : (
        <p>Loading chart data...</p>
      )}
    </div>
  );
};

const styles = {
  chartContainer: {
    position: 'relative',
    height: '100%', // Ensure it fills the container
    width: '100%', // Ensure it fills the container
    margin: '0 auto',
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '10px',
    padding: '20px',
    border: '1px solid #ddd',
    display: 'flex',
    flexDirection: 'column', // Ensure the title and chart are stacked
    justifyContent: 'space-between', // Ensure even spacing
  },
  dropdown: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1000,
  },
  dropdownButton: {
    background: '#0088cc',
    color: 'white',
    border: 'none',
    padding: '10px',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background 0.3s',
  },
  dropdownButtonHovered: {
    background: '#006699',
  },
  dropdownMenu: {
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    borderRadius: '5px',
    marginTop: '5px',
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #ddd',
    background: 'white',
    transition: 'background 0.3s',
  },
  dropdownItemHovered: {
    background: '#f0f0f0',
  },
};

export default PriceChart;
