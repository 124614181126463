import React, { useState, useEffect, useRef } from 'react';
import SolarImageWithPulsingBubbles from "../components/ElectricWire";
import ProjectDescription from "../components/ProjectDescription";
import Carousel from '../components/Carousel';
import { MoonPayBuyWidget } from '@moonpay/moonpay-react';
import logomoonpay from '../assets/logomoonpay.png';
import logobtc from '../assets/logobtc.png';
import logoeth from '../assets/logoeth.png';
import '../styles/styles.css';
import PriceChart from '../components/PriceChart';
import StripePayment from '../components/StripePayment';
import { HoverProvider } from '../components/HoverContext';
import UniswapSwap from '../components/UniswapSwap';

function Home({ timeframe }) {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);
  const imageRef = useRef(null);
  const carouselRef = useRef(null);
  const chartRef = useRef(null);
  const stripeRef = useRef(null);
  const [showMoonPayWidget, setShowMoonPayWidget] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState(0);
  const [canvasHeight, setCanvasHeight] = useState(0);
  const [showElectricWire, setShowElectricWire] = useState(false);
  const [path, setPath] = useState([]);

  const handleBuyClick = () => {
    setShowMoonPayWidget(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElectricWire(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (containerRef.current) {
      setCanvasWidth(containerRef.current.clientWidth);
      setCanvasHeight(containerRef.current.clientHeight);
    }
  }, [containerRef.current]);

  useEffect(() => {
    if (showElectricWire && buttonRef.current && imageRef.current && containerRef.current && carouselRef.current) {
      const buttonRect = buttonRef.current.getBoundingClientRect();
      const imageRect = imageRef.current.getBoundingClientRect();
      const carouselRect = carouselRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();
      const chartRect = chartRef.current.getBoundingClientRect();
      const stripeRect = stripeRef.current.getBoundingClientRect();

      const buttonTop = buttonRect.top - containerRect.top;
      const buttonLeft = buttonRect.left - containerRect.left;
      const buttonWidth = buttonRect.width;
      const buttonHeight = buttonRect.height;

      const carouselTop = carouselRect.top - containerRect.top;
      const carouselLeft = carouselRect.left - containerRect.left;
      const carouselWidth = carouselRect.width;
      const carouselHeight = carouselRect.height;

      const chartTop = chartRect.top - containerRect.top;
      const chartLeft = chartRect.left - containerRect.left;
      const chartWidth = chartRect.width;
      const chartHeight = chartRect.height;

      const stripeTop = stripeRect.top - containerRect.top;
      const stripeLeft = stripeRect.left - containerRect.left;
      const stripeWidth = stripeRect.width;
      const stripeHeight = stripeRect.height;

      const imageTop = imageRect.top - containerRect.top;
      const imageLeft = imageRect.left - containerRect.left;
      const imageWidth = imageRect.width;
      const imageHeight = imageRect.height;

      const margin = 2;
      const marg2 = -20;
      const marginCarousel = -2;

      const newPath = [
        [
          { x: imageLeft + imageWidth * 0.315, y: imageTop + imageHeight * 0.928, }, // Point 1
          { x: carouselLeft + carouselWidth / 2 - margin + marg2, y: carouselTop - margin + marg2 } // Point 2: Intersection
        ],

        [
          { x: carouselLeft + carouselWidth / 2 - margin + marg2, y: carouselTop - margin + marg2 }, // Point 2: Intersection
          { x: carouselLeft - marginCarousel + margin + marg2, y: carouselTop - margin + marg2 }, // Point 3: Top left
          { x: carouselLeft - marginCarousel +margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 4: Bottom left
          { x: (chartLeft + chartWidth / 2) - margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 7: Reunion
          { x: (chartLeft + chartWidth / 2) - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (chartLeft) +5 - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (chartLeft) +5 - margin + marg2, y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
          { x: chartLeft + (chartWidth  )  - 20 , y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
        ],

        [
          { x: (chartLeft + chartWidth / 2) - margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 7: Reunion
          { x: (stripeLeft + chartWidth / 2) - margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 7: Reunion
        ],

        [
          { x: (chartLeft + chartWidth / 2) - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (chartLeft) + chartWidth - 20 , y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (chartLeft) + chartWidth - 20 , y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
        ],

        

        [
          { x: carouselLeft + carouselWidth / 2 - margin + marg2, y: carouselTop - margin + marg2 }, // Point 2: Intersection
          { x: carouselLeft + carouselWidth -5 + margin + marg2, y: carouselTop - margin + marg2 }, // Point 6: Top right
          { x: carouselLeft + carouselWidth -5 + margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 5: Bottom right
          { x: (stripeLeft + chartWidth / 2) - margin + marg2, y: carouselTop + carouselHeight + margin + marg2 }, // Point 7: Reunion
          { x: (stripeLeft + stripeWidth / 2) - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (stripeLeft + stripeWidth) +5 - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          { x: (stripeLeft + stripeWidth) +5 - margin + marg2, y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
          { x: chartLeft + (chartWidth  ), y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
        ],

        [
          {x: (stripeLeft + stripeWidth / 2) - margin + marg2, y: chartTop + margin + marg2 }, // Point 7: Reunion
          {x: chartLeft + (chartWidth  ) , y: chartTop + margin + marg2 }, // Point 7: Reunion
          {x: chartLeft + (chartWidth  ) , y: chartTop + chartHeight + margin + marg2 }, // Point 7: Reunion
        ],

      ];

      setPath(newPath);
    }
  }, [showElectricWire]);

  return (
    <div ref={containerRef} className="container" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      <HoverProvider>
        <div style={{ position: 'relative', width: '100%' }}>
          <ProjectDescription imageRef={imageRef} />
          {path.length > 0 && (
            <div className="fade-in">
              <SolarImageWithPulsingBubbles
                canvasWidth={canvasWidth}
                canvasHeight={canvasHeight}
                paths={path}
              />
            </div>
          )}
        </div>
      </HoverProvider>

      <div className="carousel-wrapper" ref={carouselRef}>
          <Carousel />
      </div>

      <div className="dual-container">
        <div className="dual-item" ref={chartRef}>
          <PriceChart />
        </div>
        <div className="dual-item" ref = {stripeRef}>
          <StripePayment />
        </div>
      </div>

      <div className="dual-container2">
        <div className="dual-item2">
          <UniswapSwap />
        </div>
        <div className="dual-item2">
        <h1>Achat Cypto avec Carte bleu</h1>
          <div ref={buttonRef} className="button-container" onClick={handleBuyClick}>
            <div className="button-text">Buy</div>
            <div className="button-logos">
              <img src={logobtc} alt="BTC" style={{ width: '80px', height: '50px', marginBottom: '5px' }} />
              <img src={logoeth} alt="ETH" style={{ width: '50px', height: '50px' }} />
            </div>
            <div className="integrated-with">Integrated with</div>
            <img src={logomoonpay} alt="MoonPay" style={{ width: '100px', marginTop: '5px' }} />
          </div>
          
          {showMoonPayWidget && (
            
            <MoonPayBuyWidget
              variant="overlay"
              baseCurrencyCode="usd"
              baseCurrencyAmount="100"
              defaultCurrencyCode="eth"
              onLogin={async () => console.log("Customer logged in!")}
              visible
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;
