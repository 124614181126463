import React, { useEffect, useState } from 'react';
import bigInt from 'big-integer';
import { useGlobalState, useGlobalStateUpdate } from '../provider/GlobalStateProvider';
import '../styles/nftcreationpage.css';
import '../styles/nftdetails.css'; // Import the new CSS file

const PagePro2 = () => {
  const {
    nfts, selectedNft, showPrestation, selectedPrestation, userAddress, error, servicePrices, titleServiceDescription, serviceDescriptions, web3, contract, tokenContract, contractAddress, downloadTechnicalFile
  } = useGlobalState();

  const { setSelectedNft, setShowPrestation, setSelectedPrestation, setError, checkConnection, switchChain } = useGlobalStateUpdate();

  const [nftEvents, setNftEvents] = useState([]);
  const [showDetails, setShowDetails] = useState(false); // New state for controlling the details popup
  const [rating, setRating] = useState('');
  const [loadingNft, setLoadingNft] = useState(null); // New state for tracking which NFT is loading

  useEffect(() => {
    checkConnection(false);
  }, [checkConnection]);

  const handleClosePopup = () => {
    setShowDetails(false);
  };

  const handleSelectNft = async (nft) => {
    setSelectedNft(nft);
    setShowPrestation(true);
    setShowDetails(false); // Ensure the details popup is hidden when selecting an NFT
  };


  const eventNametoFrench = (description) => {
    switch(description){
      case 'NoteUpdated':return"Note mise à jour";
      case 'InterventionRequired': return "Intervention Requise";
      case 'TechnicianDataAdded': return "Documentation Technique ajoutée"
      default:return description
    }
  }

  const handleShowDetails = async (nft) => {
    setLoadingNft(nft.tokenId); // Set loading state for the specific NFT
    try {
      const nftDetails = await contract.methods.getTokenDetails(nft.tokenId).call();
      const nftWithDetails = {
        ...nft,
        rating: nftDetails[5],
        analysis: nft.analysis,
      };
      setSelectedNft(nftWithDetails);

      // Fetch events related to the selected NFT from the contract
      const latestBlock = await web3.eth.getBlockNumber();
      const blockRange = bigInt(10000); // Define the block range limit
      const eventNames = ['Mint', 'TechnicianDataAdded', 'InterventionRequired', 'NoteUpdated'];
      let allEvents = [];

      for (const eventName of eventNames) {
        for (let i = bigInt(3925483); i.lesser(latestBlock); i = i.add(blockRange)) {
          const fromBlock = i;
          const toBlock = i.add(blockRange).subtract(1).greater(latestBlock) ? bigInt(latestBlock) : i.add(blockRange).subtract(1);

          const events = await contract.getPastEvents(eventName, {
            filter: { tokenId: nft.tokenId.toString() },
            fromBlock: fromBlock.toString(),
            toBlock: toBlock.toString()
          });

          for (let event of events) {
            const block = await web3.eth.getBlock(event.blockNumber);
            event.timestamp = block.timestamp;
          }

          allEvents = [...allEvents, ...events];
        }
      }

      const formattedEvents = allEvents.map(event => ({
        timestamp: bigInt(event.timestamp),
        description: event.event,
        ...event.returnValues
      })).reverse(); // Reverse the order of events

      formattedEvents.sort((a, b) => a.timestamp.subtract(b.timestamp)).reverse();
      setNftEvents(formattedEvents);

      setShowDetails(true); // Show the details popup
    } catch (error) {
      setError('Erreur lors de la récupération des détails du NFT ou des événements : ' + error.message);
    } finally {
      setLoadingNft(null); // Clear loading state
    }
  };

  const getServiceTypeDescription = (serviceType) => {
    switch (serviceType.toString()) {
      case '0':
        return 'Inspection et Evaluation Toit';
      case '1':
        return 'Nettoyage Toit';
      case '2':
        return 'Réparation Toit';
      case '3':
        return 'Etanchéification';
      default:
        return 'Unknown Service Type';
    }
  };

  const handlePrestationChange = (index) => {
    setSelectedPrestation(index);
  };

  const handleSubmit = async () => {
    setError('');
    try {
      const serviceType = parseInt(selectedPrestation, 10);
      const price = servicePrices[serviceType];
      const priceInWei = web3.utils.toWei(price.toString(), 'ether');
      const gasPrice = await web3.eth.getGasPrice();

      await switchChain();
      await tokenContract.methods.approve(contractAddress, priceInWei).send({
        from: userAddress,
        gasPrice: gasPrice,
      });

      await switchChain();
      console.log(selectedNft, serviceType);
      await contract.methods.purchaseService(selectedNft.tokenId, serviceType).send({
        from: userAddress,
        gasPrice: gasPrice,
        gas: 200000
      });

      setShowPrestation(false);
      setSelectedPrestation('');
    } catch (error) {
      setError('Erreur lors de l\'achat de la prestation : ' + error.message);
    }
  };

  const AnalysisComponent = ({ analysis }) => {
    if(analysis === undefined){
      return (
        <div>
        <h4>Analyse IA</h4>
        </div>
      )
    }
    const analysisLines = analysis.split('\n');
    return (
      <div>
        <h4>Analyse IA</h4>
        <div>
          {analysisLines.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </div>
    );
  };

  const getMessageByRating = (note) => {
    switch (note) {
      case 'A':
        return "Toiture en très bon état, avec des problèmes mineurs ou inexistants.";
      case 'B':
        return "Quelques problèmes mineurs nécessitant une surveillance régulière.";
      case 'C':
        return "Problèmes modérés qui devraient être traités dans les prochains mois.";
      case 'D':
        return "Problèmes significatifs nécessitant une attention urgente.";
      case 'E':
        return "Problèmes graves nécessitant une intervention immédiate.";
      default:
        return "";
    }
  };  
  
  const userNfts = nfts.filter(nft => nft.targetAddress.toLowerCase() === userAddress.toLowerCase());

  return (
    <div className="container">
      <h2>Collection de NFT EcoToit</h2>
      <div className="nft-list">
        {userNfts.length === 0 ? (
          <p>Pas de NFT correspondant à votre adresse</p>
        ) : (
          userNfts.map((nft, index) => (
            <div key={index} className={`nft-item-page2 ${selectedNft && selectedNft.tokenId === nft.tokenId ? 'selected' : ''}`} onClick={() => handleSelectNft(nft)}>
              <h3>{nft.name}</h3>
              <img src={`data:image/png;base64,${nft.image}`} alt={nft.name} />
              <p>Identifiant Unique: {nft.uniqueId}</p>
              <p>Position GPS ou Adresse: {nft.address}</p>
              <p>Année de Construction: {nft.constructionYear}</p>
              <p>Client : {nft.targetAddress.slice(0, 5)}...{nft.targetAddress.slice(nft.targetAddress.length - 5)}</p>
              <button onClick={(e) => { e.stopPropagation(); handleShowDetails(nft); }}>
                {loadingNft === nft.tokenId ? 'Chargement...' : 'Voir les détails'}
              </button>
            </div>
          ))
        )}
      </div>

      {showPrestation && selectedNft && (
        <div className='prestation-list'>
          <h2>Choisissez une Prestation</h2>
          {Object.keys(servicePrices).map((index) => (
            <div key={index} className='prestation-item' onClick={() => handlePrestationChange(index)} style={{ cursor: 'pointer', border: selectedPrestation === index ? '2px solid green' : 'none' }}>
              <h3>{titleServiceDescription[index]}</h3>
              <p>{serviceDescriptions[index]}</p>
              <p>Price: {servicePrices[index]} ECT</p>
            </div>
          ))}
          <button className='button' onClick={handleSubmit}>Envoyer</button>
        </div>
      )}

      {showDetails && (
        <div className="modal-overlay" onClick={handleClosePopup}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <div className="modal-image">
                <img src={`data:image/png;base64,${selectedNft.image}`} alt={selectedNft.name} />
              </div>
              <div className="modal-info">
                <h3>{selectedNft.name}</h3>
                <p>Identifiant Unique: {selectedNft.uniqueId}</p>
                <p>Position GPS ou Adresse: {selectedNft.address}</p>
                <p>Année de Construction: {selectedNft.constructionYear}</p>
                <p>Client: {selectedNft.targetAddress}</p>
              </div>
              <div className="modal-rating">
                <h2>Note Toiture</h2>
                <div className="modal-rating-content">
                  <h1>{selectedNft.rating}</h1>
                </div>
                <p>{getMessageByRating(rating)}</p>
              </div>
            </div>
            <div className="modal-body">
              <h4>Historique des événements</h4>
              <ul>
                {nftEvents.map((event, index) => (
                  <li key={index}>
                    {eventNametoFrench(event.description)} - {new Date(event.timestamp.toJSNumber() * 1000).toString()}
                    {event.description === 'NoteUpdated' && ` - Note: ${event.note}`}
                    {event.description === 'InterventionRequired' && ` - Service: ${getServiceTypeDescription(event.serviceType)}`}
                  </li>
                ))}
              </ul>
              <AnalysisComponent analysis={selectedNft.analysis} />
              <h4>Rapport Initial (Dossier Technique)</h4>
              <button onClick={() => downloadTechnicalFile(selectedNft)}>Télécharger le dossier technique</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PagePro2;
