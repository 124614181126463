import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Web3 from 'web3';
import { useGlobalState, useGlobalStateUpdate } from '../provider/GlobalStateProvider';
import '../styles/Header.css';
import logo from '../assets/logo.svg';

const Header = () => {
  const { userAddress } = useGlobalState();
  const { setUserAddress, checkConnection, disconnectMetaMask } = useGlobalStateUpdate();
  const location = useLocation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [walletMenuOpen, setWalletMenuOpen] = useState(false);
  const walletMenuRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleWalletMenu = () => {
    setWalletMenuOpen(!walletMenuOpen);
  };

  const switchChain = async () => {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: Web3.utils.toHex(2442) }],
      });
    } catch (error) {
      if (error.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: Web3.utils.toHex(2442),
                chainName: 'Cardona zkEVM',
                nativeCurrency: {
                  name: 'Cardona zkEVM',
                  symbol: 'CZD',
                  decimals: 18,
                },
                rpcUrls: ['https://etherscan.cardona.zkevm-rpc.com/'],
                blockExplorerUrls: ['https://etherscan.cardona.zkevm-rpc.com/'],
              },
            ],
          });
        } catch (addError) {
          console.error('Failed to add the network');
        }
      }
    }
  };

  const connectMetaMask = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const accounts = await web3.eth.getAccounts();
        const chainId = await web3.eth.getChainId();

        if (chainId !== 2442) {
          await switchChain();
        }

        setUserAddress(accounts[0]);
        localStorage.setItem('account', accounts[0]);  // Save account to localStorage
        console.log(location.pathname);
        // Check if the current location is NFTCreationPage
        if (location.pathname === '/pro' || location.pathname === '/prestataire' ) {
          await checkConnection(true);  // Call checkConnection here
        }
      } catch (error) {
        console.error("User denied account access or switch chain failed", error);
      }
    } else {
      alert('MetaMask is not installed. Please install it to use this feature.');
    }
  };

  const copyAddressToClipboard = () => {
    navigator.clipboard.writeText(userAddress);
    alert('Address copied to clipboard');
  };

  useEffect(() => {
    const savedAccount = localStorage.getItem('account');
    if (savedAccount) {
      setUserAddress(savedAccount);
    }
  }, [setUserAddress]);

  const handleClickOutside = (event) => {
    if (walletMenuRef.current && !walletMenuRef.current.contains(event.target)) {
      setWalletMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const truncateAddress = (address) => {
    return address ? `${address.slice(0, 8)}...${address.slice(-8)}` : '';
  };

  return (
    <header className="header">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Logo" className="logo-image" />
        </Link>
      </div>
      <button className="menu-toggle" onClick={toggleMenu}>
        ☰
      </button>
      <nav className={`navigation ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li><Link to="/pro">Espace Pro</Link></li>
          <li><Link to="/particulier">Particulier</Link></li>
          <li><Link to="/prestataire">Hub Prestataire</Link></li>
        </ul>
      </nav>
      {(location.pathname === '/pro' || location.pathname === '/prestataire') && (
        <div className="wallet-connection">
          {userAddress ? (
            <>
              <button className="wallet-button" onClick={toggleWalletMenu}>
                <span className="wallet-address">{truncateAddress(userAddress)}</span>
              </button>
              {walletMenuOpen && (
                <div className="wallet-menu" ref={walletMenuRef}>
                  <button className="button" onClick={copyAddressToClipboard}>Copy Address</button>
                  <button className="button disconnect-button" onClick={disconnectMetaMask}>Disconnect</button>
                </div>
              )}
            </>
          ) : (
            <button className="wallet-button" onClick={connectMetaMask}>
              Connexion
            </button>
          )}
        </div>
      )}
    </header>
  );
};

export default Header;
