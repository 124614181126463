// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cgv-container {
    width: 80%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
  }
  
  .cgv-container  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .cgv-container h2 {
    margin-top: 20px;
    color: #0056b3;
  }
  
  .cgv-container p {
    margin: 10px 0;
    line-height: 1.6;
    color: #555;
  }
  
  .cgv-container ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  
  .cgv-container a {
    color: #0056b3;
    text-decoration: none;
  }
  
  .cgv-container a:hover {
    text-decoration: underline;
  }
  `, "",{"version":3,"sources":["webpack://./src/styles/CGV.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,cAAc;IACd,aAAa;IACb,yBAAyB;IACzB,mBAAmB;IACnB,wCAAwC;IACxC,gCAAgC;EAClC;;EAEA;IACE,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,gBAAgB;IAChB,cAAc;EAChB;;EAEA;IACE,cAAc;IACd,gBAAgB;IAChB,WAAW;EACb;;EAEA;IACE,qBAAqB;IACrB,iBAAiB;EACnB;;EAEA;IACE,cAAc;IACd,qBAAqB;EACvB;;EAEA;IACE,0BAA0B;EAC5B","sourcesContent":[".cgv-container {\n    width: 80%;\n    margin: 0 auto;\n    padding: 20px;\n    background-color: #f5f5f5;\n    border-radius: 10px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n    font-family: 'Arial', sans-serif;\n  }\n  \n  .cgv-container  h1 {\n    text-align: center;\n    margin-bottom: 20px;\n    color: #333;\n  }\n  \n  .cgv-container h2 {\n    margin-top: 20px;\n    color: #0056b3;\n  }\n  \n  .cgv-container p {\n    margin: 10px 0;\n    line-height: 1.6;\n    color: #555;\n  }\n  \n  .cgv-container ul {\n    list-style-type: disc;\n    margin-left: 20px;\n  }\n  \n  .cgv-container a {\n    color: #0056b3;\n    text-decoration: none;\n  }\n  \n  .cgv-container a:hover {\n    text-decoration: underline;\n  }\n  "],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
