import React, { useEffect, useState } from 'react';
import bigInt from 'big-integer';
import { useGlobalState, useGlobalStateUpdate } from '../provider/GlobalStateProvider';
import '../styles/nftcreationpage.css';
import '../styles/nftdetails.css';

const PagePro = () => {
  const {
    nftName, nftImage, nftAddress, nfts, userAddress, error, web3, contract, downloadTechnicalFile, events
  } = useGlobalState();

  const { setNftName, setNftImage, setNftAddress, setNfts, setError, checkConnection, switchChain } = useGlobalStateUpdate();

  const [uniqueId, setUniqueId] = useState('');
  const [gpsCoordinates, setGpsCoordinates] = useState('');
  const [constructionYear, setConstructionYear] = useState('');
  const [presentationPhoto, setPresentationPhoto] = useState(null);
  const [technicalFile, setTechnicalFile] = useState(null);
  const [targetAddress, setTargetAddress] = useState('');
  const [selectedNft, setSelectedNft] = useState(null);
  const [rating, setRating] = useState('');
  const [nftEvents, setNftEvents] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    checkConnection(false);
  }, [checkConnection]);

  useEffect(() => {
    if (selectedNft) {
      setRating(selectedNft.rating);
    }
  }, [selectedNft]);

  const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.split(',')[1]);
    reader.onerror = error => reject(error);
  });

  const handleCreateNft = async () => {
    if (!presentationPhoto || !technicalFile) {
      setError('Veuillez remplir tous les champs et sélectionner les fichiers requis.');
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('name', nftName);
    formData.append('address', gpsCoordinates);
    formData.append('userAddress', userAddress);
    formData.append('targetAddress', targetAddress);
    formData.append('image', presentationPhoto);
    formData.append('uniqueId', uniqueId);
    formData.append('constructionYear', constructionYear);
    formData.append('technicalFile', technicalFile);

    try {
      const apiresponse = await fetch('https://api.ecotoit.io/next-token-id');
      const { nextTokenId } = await apiresponse.json();
      const message = `Creation nft Ecotoit #${nextTokenId}`;
      await switchChain();
      const signature = await web3.eth.personal.sign(message, userAddress, '');

      formData.append('signature', signature);

      const response = await fetch('https://api.ecotoit.io/nft', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();

      if (data.success) {
        const { metadataURI, encryptedMetadataURI } = data;
        await switchChain();

        const gasPrice = await web3.eth.getGasPrice();
        await contract.methods.mint(userAddress, nftName, metadataURI, encryptedMetadataURI, targetAddress).send({
          from: userAddress,
          gasPrice: gasPrice,
        });
        const accounts = await window.ethereum.request({ method: 'eth_accounts' });
        const newNft = {
          name: nftName,
          image: await toBase64(presentationPhoto),
          userAddress:accounts[0],
          address: gpsCoordinates,
          targetAddress,
          tokenId: nextTokenId,
          constructionYear,
          uniqueId
        };
        const updatedNfts = [...nfts, newNft];
        setNfts(updatedNfts);
        setNftName('');
        setNftImage(null);
        setNftAddress('');
        setUniqueId('');
        setGpsCoordinates('');
        setConstructionYear('');
        setPresentationPhoto(null);
        setTechnicalFile(null);
        setTargetAddress('');
        console.log(newNft);
        localStorage.setItem('nfts', JSON.stringify(updatedNfts));
        localStorage.setItem('cacheTime', Date.now().toString());
      } else {
        setError('Erreur lors de la création du NFT : ' + data.error);
      }
    } catch (error) {
      setError('Erreur lors de la création du NFT : ' + error.message);
    } finally {
      setLoading(false);
    }
  };


  const eventNametoFrench = (description) => {
    switch(description){
      case 'NoteUpdated':return"Note mise à jour";
      case 'InterventionRequired': return "Intervention Requise";
      case 'TechnicianDataAdded': return "Documentation Technique ajoutée"
      default:return description
    }
  }

  const handleNftClick = async (nft) => {
    try {
      const nftDetails = await contract.methods.getTokenDetails(nft.tokenId).call();
      const nftWithDetails = {
        ...nft,
        rating: nftDetails[5],
        analysis: nft.analysis,
      };
      setSelectedNft(nftWithDetails);

      const latestBlock = await web3.eth.getBlockNumber();
      const blockRange = bigInt(10000);
      const eventNames = ['Mint', 'TechnicianDataAdded', 'InterventionRequired', 'NoteUpdated'];
      
      let allEvents = [];

      for (const eventName of eventNames) {
        for (let i = bigInt(3925483); i.lesser(latestBlock); i = i.add(blockRange)) {
          const fromBlock = i;
          const toBlock = i.add(blockRange).subtract(1).greater(latestBlock) ? bigInt(latestBlock) : i.add(blockRange).subtract(1);

          const events = await contract.getPastEvents(eventName, {
            filter: { tokenId: nft.tokenId.toString() },
            fromBlock: fromBlock.toString(),
            toBlock: toBlock.toString()
          });

          for (let event of events) {
            const block = await web3.eth.getBlock(event.blockNumber);
            event.timestamp = block.timestamp;
          }

          allEvents = [...allEvents, ...events];
        }
      }

      const formattedEvents = allEvents.map(event => ({
        timestamp: bigInt(event.timestamp),
        description: event.event,
        ...event.returnValues
      })).reverse();
      formattedEvents.sort((a, b) => a.timestamp.subtract(b.timestamp)).reverse();
      setNftEvents(formattedEvents);
    } catch (error) {
      setError('Erreur lors de la récupération des détails du NFT ou des événements : ' + error.message);
    }
  };

  const getServiceTypeDescription = (serviceType) => {
    switch (serviceType.toString()) {
      case '0':
        return 'Inspection et Evaluation Toit';
      case '1':
        return 'Nettoyage Toit';
      case '2':
        return 'Réparation Toit';
      case '3':
        return 'Etanchéification';
      default:
        return 'Unknown Service Type';
    }
  };

  const handleSetNote = async (tokenId, note) => {
    try {
      await switchChain();
      const gasPrice = await web3.eth.getGasPrice();
      await contract.methods.setNote(tokenId, note).send({
        from: userAddress,
        gasPrice: gasPrice,
      });
      setSelectedNft({ ...selectedNft, rating: note });
      setRating(note);
    } catch (error) {
      setError('Erreur lors de la mise à jour de la note : ' + error.message);
    }
  };

  const AnalysisComponent = ({ analysis }) => {
    if(analysis === undefined){
      return (
        <div>
        <h4>Analyse IA</h4>
        </div>
      )
    }
    const analysisLines = analysis.split('\n');
    console.log("nalay",analysis)
    return (
      <div>
        <h4>Analyse IA</h4>
        <div>
          {analysisLines.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      </div>
    );
  };

  const getMessageByRating = (note) => {
    switch (note) {
      case 'A':
        return "Toiture en très bon état, avec des problèmes mineurs ou inexistants.";
      case 'B':
        return "Quelques problèmes mineurs nécessitant une surveillance régulière.";
      case 'C':
        return "Problèmes modérés qui devraient être traités dans les prochains mois.";
      case 'D':
        return "Problèmes significatifs nécessitant une attention urgente.";
      case 'E':
        return "Problèmes graves nécessitant une intervention immédiate.";
      default:
        return "";
    }
  };

  const handleClosePopup = () => {
    setSelectedNft(null);
  };

  const userNfts = nfts.filter(nft => nft.userAddress.toLowerCase() === userAddress.toLowerCase());
  return (
    <div className="container">
      <div className="backdrop">
        <h1>Créer votre NFT</h1>
        <div className="input-group">
          <label>Identifiant Unique</label>
          <input
            type="text"
            placeholder="Identifiant Unique"
            value={uniqueId}
            onChange={(e) => setUniqueId(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Nom du NFT</label>
          <input
            type="text"
            placeholder="Nom du NFT"
            value={nftName}
            onChange={(e) => setNftName(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Photo de Présentation</label>
          <input
            type="file"
            onChange={(e) => setPresentationPhoto(e.target.files[0])}
          />
        </div>
        <div className="input-group">
          <label>Position GPS ou Adresse</label>
          <input
            type="text"
            placeholder="Position GPS ou Adresse"
            value={gpsCoordinates}
            onChange={(e) => setGpsCoordinates(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Année de Construction</label>
          <input
            type="text"
            placeholder="Année de Construction"
            value={constructionYear}
            onChange={(e) => setConstructionYear(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Adresse Cible</label>
          <input
            type="text"
            placeholder="Adresse Cible"
            value={targetAddress}
            onChange={(e) => setTargetAddress(e.target.value)}
          />
        </div>
        <div className="input-group">
          <label>Dossier Technique (PDF)</label>
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) => setTechnicalFile(e.target.files[0])}
          />
        </div>
        <button className="button" onClick={handleCreateNft} disabled={loading}>
          {loading ? 'Création en cours...' : 'Créer NFT'}
        </button>
      </div>

      <h2>Collection de NFT EcoToit</h2>
      <div className="nft-list">
        {userNfts.length === 0 ? (
          <p>Pas encore de NFT créé</p>
        ) : (
          userNfts.map((nft, index) => (
            <div key={index} className="nft-item" onClick={() => handleNftClick(nft)}>
              <h3>{nft.name}</h3>
              <img src={`data:image/png;base64,${nft.image}`} alt={nft.name} />
              <p>Identifiant Unique: {nft.uniqueId}</p>
              <p>Position GPS ou Adresse: {nft.address}</p>
              <p>Année de Construction: {nft.constructionYear}</p>
              <p>Client : {nft.targetAddress.slice(0,5)}...{nft.targetAddress.slice(nft.targetAddress.length - 5)}</p>
              <button onClick={() => downloadTechnicalFile(nft)}>Voir le dossier technique</button>
            </div>
          ))
        )}
      </div>

      {selectedNft && (
        <div className="modal-overlay" onClick={handleClosePopup}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <div className="modal-image">
                <img src={`data:image/png;base64,${selectedNft.image}`} alt={selectedNft.name} />
              </div>
              <div className="modal-info">
                <h3>{selectedNft.name}</h3>
                <p>Identifiant Unique: {selectedNft.uniqueId}</p>
                <p>Position GPS ou Adresse: {selectedNft.address}</p>
                <p>Année de Construction: {selectedNft.constructionYear}</p>
                <p>Client : {selectedNft.targetAddress}</p>
              </div>
              <div className="modal-rating">
                <h2>Note Toiture</h2>
                <div className="modal-rating-content">
                  <div className="modal-rating-buttons">
                    <button onClick={() => handleSetNote(selectedNft.tokenId, 'A')}>A</button>
                    <button onClick={() => handleSetNote(selectedNft.tokenId, 'B')}>B</button>
                    <button onClick={() => handleSetNote(selectedNft.tokenId, 'C')}>C</button>
                    <button onClick={() => handleSetNote(selectedNft.tokenId, 'D')}>D</button>
                    <button onClick={() => handleSetNote(selectedNft.tokenId, 'E')}>E</button>
                  </div>
                  <h1>{rating}</h1>
                </div>
                <p>{getMessageByRating(rating)}</p>
              </div>
            </div>
            <div className="modal-body">
              <h4>Historique des événements</h4>
              <ul>
                {nftEvents.map((event, index) => (
                  <li key={index}>
                    {eventNametoFrench(event.description)} - {new Date(event.timestamp.toJSNumber() * 1000).toString()}
                    {event.description === 'NoteUpdated' && ` - Note: ${event.note}`}
                    {event.description === 'InterventionRequired' && ` - Service: ${getServiceTypeDescription(event.serviceType)}`}
                  </li>
                ))}
              </ul>
              <AnalysisComponent analysis={selectedNft.analysis} />
              <h4>Rapport Initial (Dossier Technique)</h4>
              <button onClick={() => downloadTechnicalFile(selectedNft)}>Télécharger le dossier technique</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PagePro;
