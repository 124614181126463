import React from 'react';
import '../styles/CGV.css';

const CGVPage = () => {
  return (
    <div className="cgv-container">
      <h1>Conditions Générales de Vente (CGV)</h1>
      <section>
        <h2>1. Objet</h2>
        <p>
          Les présentes Conditions Générales de Vente (CGV) régissent les relations contractuelles entre la SAS Active Toit, située au 24 allée Paul Decauville, 26000 Valence, France, SIRET 80016464200012 (ci-après dénommée "Active Toit") et les utilisateurs de la plateforme Ecotoit (ci-après dénommés "le Client"). Ecotoit facilite la mise en relation entre les particuliers/professionnels et les prestataires de services pour des travaux de toiture, tout en assurant l’enregistrement et le suivi des services dans la blockchain.
        </p>
      </section>
      <section>
        <h2>2. Acceptation des Conditions</h2>
        <p>
          L’utilisation de la plateforme Ecotoit implique l’acceptation pleine et entière des présentes CGV par le Client. Active Toit se réserve le droit de modifier à tout moment les CGV, les modifications étant applicables dès leur publication sur le site ecotoit.io.
        </p>
      </section>
      <section>
        <h2>3. Services Proposés</h2>
        <p>Ecotoit offre les services suivants :</p>
        <ul>
          <li>Mise en relation pour Diagnostic et Recherche de Fuites</li>
          <li>Mise en relation pour Entretien de Toiture</li>
          <li>Mise en relation pour Réparation et Mesures Conservatoires</li>
          <li>Mise en relation pour Réfection Complète</li>
        </ul>
      </section>
      <section>
        <h2>4. Rôle de Ecotoit</h2>
        <p>
          Ecotoit agit comme intermédiaire en fournissant une plateforme qui permet aux Clients de sélectionner et d’engager des prestataires de services pour des travaux de toiture. Ecotoit enregistre également ces services sur la blockchain pour garantir leur traçabilité.
        </p>
      </section>
      <section>
        <h2>5. Commandes et Paiements</h2>
        <p>
          Les commandes de services se font via la plateforme Ecotoit. Les paiements pour les services de mise en relation peuvent être effectués en monnaie fiat ou en cryptomonnaie. Active Toit utilise MoonPay pour les paiements en cryptomonnaies (BTC, ETH, etc.) et Stripe pour les paiements en fiat. Les paiements pour les services rendus par les prestataires sont réglés directement entre le Client et le prestataire.
        </p>
      </section>
      <section>
        <h2>6. Tarifs</h2>
        <p>
          Les tarifs pour l’utilisation de la plateforme Ecotoit et les services de mise en relation sont affichés sur le site et peuvent varier. Les prix sont indiqués en euros, hors taxes, et sont payables selon les modalités précisées lors de la commande.
        </p>
      </section>
      <section>
        <h2>7. Exécution des Prestations</h2>
        <p>
          Les prestations sont exécutées par des prestataires indépendants inscrits sur Ecotoit. Active Toit ne garantit pas la qualité ou l’exécution des travaux effectués par ces prestataires, mais assure l’enregistrement des prestations via la blockchain.
        </p>
      </section>
      <section>
        <h2>8. Annulation et Rétractation</h2>
        <p>
          Le Client dispose d’un droit de rétractation de 14 jours pour annuler la commande de mise en relation, sauf si l’exécution du service a commencé avec son accord préalable. Les frais de mise en relation ne sont pas remboursables si le service a été fourni.
        </p>
      </section>
      <section>
        <h2>9. Responsabilité</h2>
        <p>
          Active Toit n’est pas responsable des prestations de service réalisées par les prestataires. La responsabilité de Active Toit se limite à la fourniture de la plateforme et à l’enregistrement des services sur la blockchain. Toute réclamation relative aux travaux doit être adressée directement au prestataire concerné.
        </p>
      </section>
      <section>
        <h2>10. Garantie</h2>
        <p>
          Les garanties relatives aux prestations sont fournies par les prestataires de services et non par Active Toit. Les réclamations concernant la non-conformité ou la qualité des travaux doivent être adressées directement au prestataire.
        </p>
      </section>
      <section>
        <h2>11. Protection des Données</h2>
        <p>
          Active Toit s’engage à respecter la confidentialité des données personnelles des Clients, collectées et traitées conformément à la réglementation en vigueur. Les données enregistrées sur la blockchain sont sécurisées et consultables par le Client via son compte.
        </p>
      </section>
      <section>
        <h2>12. Propriété Intellectuelle</h2>
        <p>
          Tous les contenus et éléments présents sur la plateforme Ecotoit, y compris les logiciels, sont protégés par le droit d'auteur et sont la propriété exclusive de Active Toit. Toute reproduction non autorisée est interdite.
        </p>
      </section>
      <section>
        <h2>13. Litiges</h2>
        <p>
          Les présentes CGV sont soumises au droit français. En cas de litige, les parties s'efforceront de trouver une solution amiable avant de porter l'affaire devant les tribunaux compétents de Valence.
        </p>
      </section>
      <section>
        <h2>14. Contact</h2>
        <p>
          Pour toute question relative aux CGV, le Client peut contacter Active Toit à l’adresse suivante : <a href="mailto:contact@ecotoit.io">contact@ecotoit.io</a> ou par courrier à SAS Active Toit, 24 allée Paul Decauville, 26000 Valence, France.
        </p>
      </section>
    </div>
  );
};

export default CGVPage;
