// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/roofing-theme.css */

body {
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #F5F5F5;
}
`, "",{"version":3,"sources":["webpack://./src/styles/roofing-theme.css"],"names":[],"mappings":"AAAA,iCAAiC;;AAEjC;EACE,oCAAoC;EACpC,SAAS;EACT,UAAU;EACV,yBAAyB;AAC3B","sourcesContent":["/* src/styles/roofing-theme.css */\n\nbody {\n  font-family: 'Open Sans', sans-serif;\n  margin: 0;\n  padding: 0;\n  background-color: #F5F5F5;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
