import React, { useState } from 'react';

const PageParticulier = () => {
  const [prestations] = useState([
    { name: 'Prestation 1', description: 'Description de la prestation 1' },
    { name: 'Prestation 2', description: 'Description de la prestation 2' },
    { name: 'Prestation 3', description: 'Description de la prestation 3' },
  ]);
  const [selectedPrestation, setSelectedPrestation] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [formData, setFormData] = useState({
    address: '',
    superficie: '',
    toit: '',
  });

  const handleSelectPrestation = (prestation) => {
    setSelectedPrestation(prestation);
    setShowDetails(true);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    console.log('Prestation choisie:', selectedPrestation);
    console.log('Données soumises:', formData);
    setShowDetails(false);
  };

  return (
    <div>
      <h1>Choisissez une Prestation</h1>

      <h2>Prestations Disponibles</h2>
      <div>
        {prestations.length === 0 ? (
          <p>Pas de prestations disponibles.</p>
        ) : (
          prestations.map((prestation, index) => (
            <div key={index} onClick={() => handleSelectPrestation(prestation)}>
              <h3>{prestation.name}</h3>
              <p>{prestation.description}</p>
            </div>
          ))
        )}
      </div>

      {showDetails && selectedPrestation && (
        <div>
          <h2>Informations Complémentaires</h2>
          <p>Prestation: {selectedPrestation.name}</p>
          <div>
            <label>
              Adresse: 
              <input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label>
              Superficie: 
              <input
                type="text"
                name="superficie"
                value={formData.superficie}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <div>
            <label>
              Toit: 
              <input
                type="text"
                name="toit"
                value={formData.toit}
                onChange={handleInputChange}
              />
            </label>
          </div>
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </div>
  );
};

export default PageParticulier;
