import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import bigInt from 'big-integer';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import '../styles/styles.css'; // Importez votre fichier CSS

const stripePromise = loadStripe('pk_test_51PTdOAJVAfQl4a6cTW8fqyfVmIC3PbKL9MdAM5Hjlj4u31wOUXlY6Mj8tavo66crcT6fL9KtLImzreUKbe9IHADE00Y5AZZHh9');

const convertSqrtPriceX96ToPrice = (sqrtPriceX96) => {
  const price = (bigInt(sqrtPriceX96) ** bigInt(2)) / (bigInt(2) ** bigInt(192));
  return Number((1 / price) * 10 ** 12);
};

const fetchLatestPrice = async () => {
  const response = await fetch('https://api.ecotoit.io/price-data?timeframe=12h');
  const data = await response.json();
  const number = convertSqrtPriceX96ToPrice(Number(data[data.length-1].price));
  const number100 = number * 100;
  return Math.round(number100) / 100;
};

const CheckoutForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [paymentProcessing, setPaymentProcessing] = useState(false);
  const [address, setAddress] = useState('');
  const [tokens, setTokens] = useState(1);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const getPrice = async () => {
      const latestPrice = await fetchLatestPrice();
      setPrice(latestPrice);
    };
    getPrice();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setPaymentProcessing(true);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });

    if (error) {
      setError(error.message);
      setPaymentProcessing(false);
    } else {
      const response = await fetch('https://api.ecotoit.io/pay', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          address,
          tokens,
          amount: price * tokens,
        }),
      });

      const paymentResult = await response.json();

      if (paymentResult.error) {
        setError(paymentResult.error);
        setPaymentProcessing(false);
      } else {
        setError(null);
        setPaymentProcessing(false);
        alert("Le paiement a bien effectué vous recevrez bientôt les tokens ECT")
      }
    }
  };

  const handleTokenChange = (event) => {
    setTokens(event.target.value);
  };

  const handleTokenInputChange = (event) => {
    const value = event.target.value;
    if (/^\d+$/.test(value) && value >= 1 && value <= 1000000) {
      setTokens(value);
    }
  };

  return (
    <div className="form-container">
      <h2>Acheter EcoToit</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Adresse EVM:</label>
          <input
            type="text"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Nombre de ECT à acheter:</label>
          <input
            type="range"
            min="1"
            max="1000000"
            value={tokens}
            onChange={handleTokenChange}
            className="range-input"
          />
          <input
            type="number"
            min="1"
            max="1000000"
            value={tokens}
            onChange={handleTokenInputChange}
            className="token-input"
          />
        </div>
        <div className="total-price">
          Prix total: {price * tokens} USD
        </div>
        <div className="form-group">
          <CardElement />
        </div>
        <button type="submit" className="submit-button" disabled={!stripe || paymentProcessing}>
          {paymentProcessing ? 'Paiment en cours...' : 'Payer'}
        </button>
        {error && <div className="error-message">{error}</div>}
      </form>
    </div>
  );
};

const StripePayment = () => (
  <Elements stripe={stripePromise}>
    <CheckoutForm />
  </Elements>
);

export default StripePayment;
