import React, { useEffect, useRef, useState } from 'react';
import { useHover } from './HoverContext';

const SolarImageWithPulsingBubbles = ({ canvasWidth, canvasHeight, paths }) => {
  const canvasRef = useRef(null);
  const { hover } = useHover();
  const speedPerSecond = 50; // Pixels per second
  const bubbleIntervalMs = 2000; // Interval in milliseconds for creating new bubbles
  const bubbleRadius = 2;
  const pulseSpeed = 2; // Frequency of brightness pulsation (times per second)

  const [bubbles, setBubbles] = useState([]);

  const bubbleTimerRef = useRef(null);
  const resetTimerRef = useRef(null);

  const calculateSegmentLength = (start, end) => {
    const dx = end.x - start.x;
    const dy = end.y - start.y;
    return Math.hypot(dx, dy);
  };

  const drawSegment = (context, start, end) => {
    context.lineWidth = 8; // Border width
    context.strokeStyle = '#000000'; // Black border
    context.lineJoin = 'round';
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.stroke();

    context.lineWidth = 4; // Main wire width
    context.strokeStyle = '#0088cc'; // Main wire color
    context.beginPath();
    context.moveTo(start.x, start.y);
    context.lineTo(end.x, end.y);
    context.stroke();
  };

  const drawBubblesOnSegment = (context, start, end, bubbles, pulseSpeed, bubbleRadius) => {
    const segmentLength = calculateSegmentLength(start, end);
    const dx = (end.x - start.x) / segmentLength;
    const dy = (end.y - start.y) / segmentLength;

    const currentTime = performance.now();
    bubbles.forEach((bubble) => {
      const elapsedSeconds = (currentTime - bubble.startTime) / 1000;
      const distance = elapsedSeconds * speedPerSecond;

      if (distance > segmentLength) {
        return;
      }

      const bubbleX = start.x + dx * distance;
      const bubbleY = start.y + dy * distance;

      const brightness = 0.7 + 0.3 * Math.sin(2 * Math.PI * pulseSpeed * elapsedSeconds);
      context.fillStyle = `rgba(255, 255, 255, ${brightness.toFixed(2)})`;
      context.beginPath();
      context.arc(bubbleX, bubbleY, bubbleRadius, 0, 2 * Math.PI);
      context.fill();
    });
  };

  const startBubbleTimer = (start, end) => {
    const segmentLength = calculateSegmentLength(start, end);
    const duration = segmentLength / speedPerSecond;

    const newBubble = { startTime: performance.now(), split: false, segmentStart: start, segmentEnd: end };
    setBubbles((prevBubbles) => [...prevBubbles, newBubble]);

    setTimeout(() => {
      setBubbles((prevBubbles) => prevBubbles.filter((bubble) => bubble !== newBubble));
    }, duration * 1000);
  };

  useEffect(() => {
    if (bubbleTimerRef.current) {
      clearInterval(bubbleTimerRef.current);
    }

    paths.forEach(path => {
      for (let i = 0; i < path.length - 1; i++) {
        startBubbleTimer(path[i], path[i + 1]);
      }
    });

    bubbleTimerRef.current = setInterval(() => {
      paths.forEach(path => {
        for (let i = 0; i < path.length - 1; i++) {
          startBubbleTimer(path[i], path[i + 1]);
        }
      });
    }, bubbleIntervalMs);

    return () => clearInterval(bubbleTimerRef.current);
  }, [paths]);

  useEffect(() => {
    const resetBubbles = () => {
      setBubbles([]);
    };

    resetTimerRef.current = setInterval(() => {
      resetBubbles();
    }, 2 * 60 * 1000); // Reset every 2 minutes

    return () => clearInterval(resetTimerRef.current);
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    const animate = () => {
      context.clearRect(0, 0, canvas.width, canvas.height);

      paths.forEach(path => {
        for (let i = 0; i < path.length - 1; i++) {
          const start = path[i];
          const end = path[i + 1];
          drawSegment(context, start, end);
          drawBubblesOnSegment(context, start, end, bubbles.filter(bubble => bubble.segmentStart === start && bubble.segmentEnd === end), pulseSpeed, bubbleRadius);
        }
      });

      requestAnimationFrame(animate);
    };

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    requestAnimationFrame(animate);
  }, [bubbles, canvasWidth, canvasHeight, paths]);

  return (
    <canvas ref={canvasRef} className={`solar-canvas ${hover ? 'hover' : ''}`} />
  );
};

export default SolarImageWithPulsingBubbles;
